import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "swiper/css";
import "swiper/css/pagination";
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState, lazy, Suspense } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";
import NGOLoader from "./components/NGOLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomePageInfo,
  getContactDetailsInfo,
  getVideoDetailsInfo,  
  getCausesInfo,
} from "./Redux/Home/thunk";
import SnapToggle from "./components/SnapToggle";
import LiveChat from "./components/LiveChat";

function App() {
  const [language, setSelectedLanguage] = useState(
    localStorage.getItem("language") || "1"
  );

  const handleLanguageChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLanguage(selectedValue);
    localStorage.setItem("language", selectedValue);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    }
  }, []);

  const {
    loading,
    organization,
    headerImages,
    testimonials,
    partners,
    videoSection,
    aboutUs,
    contactSection,
    impacts,
    contactDetails,
    stories,
    notices,
    awards,
    events,
    videos,
    causes,
  } = useSelector((state) => state.home);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(getHomePageInfo());
      dispatch(getContactDetailsInfo());
      dispatch(getVideoDetailsInfo());
      dispatch(getCausesInfo());
    }
  }, []);

  const Home = lazy(() => import("./pages/Home"));
  const PhotoGalleryCategory = lazy(() =>
    import("./pages/PhotoGalleryCategory")
  );
  const PhotoGallery = lazy(() => import("./pages/PhotoGallery"));
  const VideoGallery = lazy(() => import("./pages/VideoGallery"));
  const OurTeam = lazy(() => import("./pages/OurTeam"));
  const ContactUs = lazy(() => import("./pages/ContactUs"));
  const Donate = lazy(() => import("./pages/Donate"));
  const Stories = lazy(() => import("./pages/Stories"));
  const NoticePage = lazy(() => import("./pages/NoticePage"));
  const AboutUs = lazy(() => import("./pages/AboutUs"));
  const NewsSection = lazy(() => import("./pages/NewsSection"));
  const EventsSection = lazy(() => import("./pages/EventsSection"));
  const JoinUs = lazy(() => import("./pages/JoinUs"));
  const Notices = lazy(() => import("./pages/Notices"));
  const JoinUsDetail = lazy(() => import("./pages/JoinUsDetail"));
  const PageNotFound = lazy(() => import("./pages/PageNotFound"));
  const Testimonials = lazy(() => import("./pages/Testimonials"));
  const Causes = lazy(() => import("./pages/Causes"));
  const CausesSingle = lazy(() => import("./pages/CausesSingle"));

  const VideoGalleryCategory = lazy(() =>
    import("./pages/VideoGalleryCategory")
  );

  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div>
      {loading ? (
        <NGOLoader />
      ) : (
        <>
          <AnimatedCursor
            innerSize={8}
            outerSize={35}
            color="0, 157, 2"
            outerAlpha={0.3}
            innerScale={0.7}
            outerScale={1.2}
          />
          <Suspense fallback={<NGOLoader />}>
            <SnapToggle />
            <LiveChat />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    organization={organization}
                    headerImages={headerImages}
                    testimonials={testimonials}
                    partners={partners}
                    videoSection={videoSection}
                    aboutUs={aboutUs}
                    contactSection={contactSection}
                    impacts={impacts}
                    contactDetails={contactDetails}
                    stories={stories}
                    notices={notices}
                    awards={awards}
                    events={events}
                    videos={videos}
                    causes={causes}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/photo-gallery"
                element={
                  <PhotoGalleryCategory
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/photo-gallery/gallery-photos/:id"
                element={
                  <PhotoGallery
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/video-gallery-category/video-gallery/:id"
                element={
                  <VideoGallery
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/our-team"
                element={
                  <OurTeam
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/contact-us"
                element={
                  <ContactUs
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/donate-now"
                element={
                  <Donate
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/stories/:id"
                element={
                  <Stories
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/notices/:id"
                element={
                  <NoticePage
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/notices"
                element={
                  <Notices
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/about-us"
                element={
                  <AboutUs
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/news"
                element={
                  <NewsSection
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/events"
                element={
                  <EventsSection
                    organization={organization}
                    language={language}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/forms"
                element={
                  <JoinUs
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/jobs-detail/:id"
                element={
                  <JoinUsDetail
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/video-gallery-category"
                element={
                  <VideoGalleryCategory
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/testimonials"
                element={
                  <Testimonials
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                    testimonials={testimonials}
                  />
                }
              />
              <Route
                path="/causes"
                element={
                  <Causes
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route
                path="/causes/:id"
                element={
                  <CausesSingle
                    language={language}
                    organization={organization}
                    handleLanguageChange={handleLanguageChange}
                  />
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </>
      )}
    </div>
  );
}

export default App;
