import { aboutConstants } from "./constants";

const initialState = {
  aboutUs: null,
};
const aboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case aboutConstants.FETCH_ABOUT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: action.payload,
      };
    case aboutConstants.FETCH_ABOUT_INFO_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default aboutReducer;
