import { imageCategoryConstants } from "./constants";

const initialState = {
  categories: [],
  count: null,
  next: null,
  previous: null,
  loading: false,
};
const imageCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case imageCategoryConstants.LOADING_IMAGE_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case imageCategoryConstants.GET_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case imageCategoryConstants.GET_IMAGE_CATEGORY_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    case imageCategoryConstants.GET_ALL_IMAGE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload,
        count: null,
        next: null,
        previous: null,
        loading: false,
      };
    case imageCategoryConstants.GET_ALL_IMAGE_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default imageCategoryReducer;
