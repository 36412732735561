import { imageGalleryConstants } from "./constants";

const initialState = {
  images: [],
  count: null,
  next: null,
  previous: null,
  loading: false,
};
const imageCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case imageGalleryConstants.LOADING_IMAGE_GALLERY:
      return {
        ...state,
        loading: true,
      };
    case imageGalleryConstants.GET_IMAGE_GALLERY_SUCCESS:
      return {
        ...state,
        images: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case imageGalleryConstants.GET_IMAGE_GALLERY_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    case imageGalleryConstants.GET_ALL_IMAGE_GALLERY_SUCCESS:
      return {
        ...state,
        images: action.payload,
        count: null,
        next: null,
        previous: null,
        loading: false,
      };
    case imageGalleryConstants.GET_ALL_IMAGE_GALLERY_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default imageCategoryReducer;
