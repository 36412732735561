import React, { useEffect, useState } from "react";
import { FaAmbulance } from "react-icons/fa";

const NGOLoader = () => {
  const [loadingText, setLoadingText] = useState("Loading");

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === "Loading...") {
          return "Almost there";
        } else if (prevText === "Almost there...") {
          return "Almost there";
        } else {
          return prevText + ".";
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="loader-container">
      <div className="loader-animation">
        <FaAmbulance className="ambulance-icon" />
      </div>
      <p className="loader-text">{loadingText}</p>
    </div>
  );
};

export default NGOLoader;
