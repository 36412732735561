import { jobsConstants } from "./constants";

const initialState = {
  jobs: [],
  jobsCategory: [],
  job: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingJobsCategory: false,
};
const jobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case jobsConstants.LOADING_JOBS:
      return {
        ...state,
        loading: true,
      };
    case jobsConstants.GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case jobsConstants.GET_JOBS_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    case jobsConstants.LOADING_JOBS_CATEGORY:
      return {
        ...state,
        loadingJobsCategory: true,
      };
    case jobsConstants.GET_JOBS_CATEGORY_SUCCESS:
      return {
        ...state,
        jobsCategory: action.payload.results,
        loadingJobsCategory: false,
      };
    case jobsConstants.GET_JOBS_CATEGORY_FAIL:
      return {
        ...state,
        edit: false,
        loadingJobsCategory: false,
      };
    default:
      return state;
  }
};
export default jobsReducer;
