import { videoGalleryConstants } from "./constants";

const initialState = {
  categories: [],
  count: null,
  next: null,
  previous: null,
  loadingCategory: false,
  videos: [],
  countVideos: null,
  nextVideos: null,
  previousVideos: null,
  loadingVideos: false,
};
const videoGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case videoGalleryConstants.LOADING_VIDEO_CATEGORY:
      return {
        ...state,
        loadingCategory: true,
      };
    case videoGalleryConstants.GET_VIDEO_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loadingCategory: false,
      };
    case videoGalleryConstants.GET_VIDEO_CATEGORY_FAIL:
      return {
        ...state,
        edit: false,
        loadingCategory: false,
      };
    case videoGalleryConstants.LOADING_VIDEOS:
      return {
        ...state,
        loadingVideos: true,
      };
    case videoGalleryConstants.GET_VIDEOS_GALLERY_SUCCESS:
      return {
        ...state,
        videos: action.payload.results,
        countVideos: null,
        nextVideos: null,
        previousVideos: null,
        loadingVideos: false,
      };
    case videoGalleryConstants.GET_VIDEOS_GALLERY_FAIL:
      return {
        ...state,
        loadingVideos: false,
      };
    default:
      return state;
  }
};
export default videoGalleryReducer;
