import { combineReducers } from "redux";
import alertReducer from "./Alert/reducer";
import homeReducer from "./Home/reducer";
import aboutReducer from "./AboutUs/reducer";
import imageCategoryReducer from "./ImageCategory/reducer";
import imageGalleryReducer from "./ImageGallery/reducer";
import noticesReducer from "./Notices/reducer";
import eventsReducer from "./Events/reducer";
import teamsReducer from "./Teams/reducer";
import jobsReducer from "./Jobs/reducer";
import videoGalleryReducer from "./VideoGallery/reducer";
import newsReducer from "./News/reducer";
import causesReducer from "./Causes/reducer";

const rootReducer = combineReducers({
  alert: alertReducer,
  home: homeReducer,
  about: aboutReducer,
  imageCategory: imageCategoryReducer,
  imageGallery: imageGalleryReducer,
  notices: noticesReducer,
  events: eventsReducer,
  members: teamsReducer,
  jobs: jobsReducer,
  videoGallery: videoGalleryReducer,
  news: newsReducer,
  causes: causesReducer,
});

export default rootReducer;
