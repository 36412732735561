import { homeConstants } from "./constants";

const initialState = {
  loading: false,
  organization: null,
  headerImages: [],
  testimonials: [],
  partners: [],
  aboutUs: null,
  contactSection: null,
  impacts: [],
  contactDetails: [],
  stories: [],
  notices: [],
  awards: [],
  events: [],
  videos: [],
  causes: [],
};
const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.LOADING:
      return {
        ...state,
        loading: true,
      };
    case homeConstants.FETCH_HOME_INFO_SUCCESS:
      return {
        ...state,
        organization: action.payload.organization,
        headerImages: action.payload.headerImages,
        testimonials: action.payload.testimonials,
        partners: action.payload.partners,
        aboutUs: action.payload.aboutUs,
        contactSection: action.payload.contactSection,
        impacts: action.payload.impacts,
        stories: action.payload.stories,
        notices: action.payload.notices,
        awards: action.payload.awards,
        events: action.payload.events,
      };
    case homeConstants.FETCH_HOME_INFO_FAIL:
      return {
        ...state,
        loading: true,
      };
    case homeConstants.FETCH_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: action.payload.results,
      };
    case homeConstants.FETCH_CONTACT_DETAILS_FAIL:
      return {
        ...state,
        loading: true,
      };
    case homeConstants.FETCH_VIDEOS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: action.payload.results,
      };
    case homeConstants.FETCH_VIDEOS_DETAILS_FAIL:
      return {
        ...state,
        loading: true,
      };
    case homeConstants.FETCH_CAUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        causes: action.payload.results,
      };
    case homeConstants.FETCH_CAUSES_FAIL:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
};
export default homeReducer;
