import { eventsConstants } from "./constants";

const initialState = {
  events: [],
  event: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  eventsCategories: [],
  loadingEventsCategory: [],
};
const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case eventsConstants.LOADING_EVENTS:
      return {
        ...state,
        loading: true,
      };
    case eventsConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case eventsConstants.GET_EVENTS_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    case eventsConstants.LOADING_EVENTS_CATEGORY:
      return {
        ...state,
        loadingEventsCategory: true,
      };
    case eventsConstants.GET_EVENTS_CATEGORY_SUCCESS:
      return {
        ...state,
        eventsCategories: action.payload.results,
        loadingEventsCategory: false,
      };
    case eventsConstants.GET_EVENTS_CATEGORY_FAIL:
      return {
        ...state,
        loadingEventsCategory: false,
      };
    default:
      return state;
  }
};
export default eventsReducer;
