import { homeConstants } from "./constants";
// actions
export const loadingAction = () => ({
  type: homeConstants.LOADING,
});

export const fetchHomeInfoSuccess = (data) => ({
  type: homeConstants.FETCH_HOME_INFO_SUCCESS,
  payload: data,
});

export const fetchHomeInfoFail = (error) => ({
  type: homeConstants.FETCH_HOME_INFO_FAIL,
  payload: error,
});

export const fetchContactDetailSuccess = (data) => ({
  type: homeConstants.FETCH_CONTACT_DETAILS_SUCCESS,
  payload: data,
});

export const fetchContactDetailFail = (error) => ({
  type: homeConstants.FETCH_CONTACT_DETAILS_FAIL,
  payload: error,
});

export const fetchVideosDetailsSuccessAction = (data) => ({
  type: homeConstants.FETCH_VIDEOS_DETAILS_SUCCESS,
  payload: data,
});

export const fetchVideosDetailsFailAction = (error) => ({
  type: homeConstants.FETCH_VIDEOS_DETAILS_FAIL,
  payload: error,
});

export const fetchCausesSuccess = (data) => ({
  type: homeConstants.FETCH_CAUSES_SUCCESS,
  payload: data,
});

export const fetchCausesFail = (error) => ({
  type: homeConstants.FETCH_CAUSES_FAIL,
  payload: error,
});
