//constants for the ACCOUNT
export const teamsConstants = {
  LOADING_TEAMS: "LOADING_TEAMS",
  GET_TEAMS_SUCCESS: "GET_TEAMS_SUCCESS",
  GET_TEAMS_FAIL: "GET_TEAMS_FAIL",
  LOADING_TEAM_HEADER_IMAGE: "LOADING_TEAM_HEADER_IMAGE",
  GET_TEAM_HEADER_IMAGE_SUCCESS: "GET_TEAM_HEADER_IMAGE_SUCCESS",
  GET_TEAM_HEADER_IMAGE_FAIL: "GET_TEAM_HEADER_IMAGE_FAIL",
  LOADING_BRANCHES: "LOADING_BRANCHES",
  GET_BRANCHES_SUCCESS: "GET_BRANCHES_SUCCESS",
  GET_BRANCHES_FAIL: "GET_BRANCHES_FAIL",
};
