import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "./index";
import storage from "redux-persist/lib/storage";

const middleware = [thunk];
const initialState = {};
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "organization", "sidebar"],
  // expires: 60 * 60 * 1000,
};

const reducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  reducer,
  initialState,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);
// const persistor = persistStore(store, null, () => {
//   // Once the store is rehydrated, this callback function will be executed
//   // This is where you can check if it's a hard reload and clear the store if necessary

//   if (performance.getEntriesByType("navigation").length === 0) {
//     // performance.getEntriesByType("navigation") returns an empty array for a hard reload

//     persistor.purge(); // Clears the persisted data from the storage
//   }
// });
const persistor = persistStore(store);
export { store, persistor };
