export const homeConstants = {
  LOADING: "LOADING",
  FETCH_HOME_INFO_SUCCESS: "FETCH_HOME_INFO_SUCCESS",
  FETCH_HOME_INFO_FAIL: "FETCH_HOME_INFO_FAIL",
  FETCH_CONTACT_DETAILS_SUCCESS: "FETCH_CONTACT_DETAILS_SUCCESS",
  FETCH_CONTACT_DETAILS_FAIL: "FETCH_CONTACT_DETAILS_FAIL",
  FETCH_VIDEOS_DETAILS_SUCCESS: "FETCH_VIDEOS_DETAILS_SUCCESS",
  FETCH_VIDEOS_DETAILS_FAIL: "FETCH_VIDEOS_DETAILS_FAIL",
  FETCH_CAUSES_SUCCESS: "FETCH_CAUSES_SUCCESS",
  FETCH_CAUSES_FAIL: "FETCH_CAUSES_FAIL",
};
