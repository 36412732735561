import { noticesConstants } from "./constants";

const initialState = {
  notices: [],
  notice: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
};
const noticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case noticesConstants.LOADING_NOTICES:
      return {
        ...state,
        loading: true,
      };
    case noticesConstants.GET_NOTICES_SUCCESS:
      return {
        ...state,
        notices: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case noticesConstants.GET_NOTICES_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    default:
      return state;
  }
};
export default noticesReducer;
