import axiosInstance from "../../utils/axios";

// for home page info
export const getHomePageInfo = async () => {
  return axiosInstance.get(`api/v1/home-app/home-page-info`);
};

export const subscribeNewsLetter = async (email) => {
  return axiosInstance.get(`api/v1/home-app/subscribe-newsletters/${email}`);
};

// for contact details
export const getContactDetails = async () => {
  return axiosInstance.get(
    `api/v1/home-app/contact-section-detail?ordering=id&limit=0`
  );
};

export const getVideosSectionDetails = async () => {
  return axiosInstance.get(
    `api/v1/home-app/video-section?ordering=-created_at_ad&limit=0`
  );
};

export const getNoticeInfo = async (id) => {
  return axiosInstance.get(`api/v1/notice-app/notices/${id}`);
};

export const getStoryInfo = async (id) => {
  return axiosInstance.get(`api/v1/story-app/stories/${id}`);
};

export const contactForm = async (body) => {
  return axiosInstance.post(`api/v1/core-app/contact-form`, body);
};

export const getHomeCauses = async () => {
  return axiosInstance.get(
    `api/v1/causes-app/causes?created_at_ad=-id&limit=3`
  );
};
