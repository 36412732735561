import { teamsConstants } from "./constants";

const initialState = {
  members: [],
  headerImage: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingHeaderImage: false,
  branches: [],
  loadingBranches: false,
};
const teamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case teamsConstants.LOADING_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case teamsConstants.GET_TEAMS_SUCCESS:
      return {
        ...state,
        members: action.payload.results,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
        loading: false,
      };
    case teamsConstants.GET_TEAMS_FAIL:
      return {
        ...state,
        edit: false,
        loading: false,
      };
    case teamsConstants.LOADING_TEAM_HEADER_IMAGE:
      return {
        ...state,
        loadingHeaderImage: true,
      };
    case teamsConstants.GET_TEAM_HEADER_IMAGE_SUCCESS:
      return {
        ...state,
        headerImage: action.payload,
        loadingHeaderImage: false,
      };
    case teamsConstants.GET_TEAM_HEADER_IMAGE_FAIL:
      return {
        ...state,
        edit: false,
        loadingHeaderImage: false,
      };
    case teamsConstants.LOADING_BRANCHES:
      return {
        ...state,
        loadingBranches: true,
      };
    case teamsConstants.GET_BRANCHES_SUCCESS:
      return {
        ...state,
        branches: action.payload.results,
        loadingBranches: false,
      };
    case teamsConstants.GET_BRANCHES_FAIL:
      return {
        ...state,
        edit: false,
        loadingBranches: false,
      };
    default:
      return state;
  }
};
export default teamsReducer;
