import { errorFunction, successFunction } from "../../utils/Alert";
import * as action from "./action";
import * as API from "./api";

export const getHomePageInfo = () => async (dispatch) => {
  try {
    dispatch(action.loadingAction());
    const { data } = await API.getHomePageInfo();
    dispatch(action.fetchHomeInfoSuccess({ ...data }));
  } catch (err) {
    errorFunction("Something went wrong");
    dispatch(action.fetchHomeInfoFail(err));
  }
};

export const subscribeNewsLetter = (email) => async (dispatch) => {
  try {
    const { data } = await API.subscribeNewsLetter(email);
    successFunction("Subscription Mail Sent Successfully");
  } catch (err) {
    errorFunction(err?.response?.data?.msg);
  }
};

export const getContactDetailsInfo = () => async (dispatch) => {
  try {
    const { data } = await API.getContactDetails();
    dispatch(action.fetchContactDetailSuccess({ ...data }));
  } catch (err) {
    errorFunction("Something went wrong");
    dispatch(action.fetchContactDetailFail(err));
  }
};

export const getVideoDetailsInfo = () => async (dispatch) => {
  try {
    const { data } = await API.getVideosSectionDetails();
    dispatch(action.fetchVideosDetailsSuccessAction({ ...data }));
  } catch (err) {
    errorFunction("Something went wrong");
    dispatch(action.fetchVideosDetailsFailAction(err));
  }
};

export const contactForm =
  (name, email, phoneNo, message) => async (dispatch) => {
    try {
      const body = { name, email, phoneNo, message };
      await API.contactForm(body);
      successFunction("Contacted Successfully");
    } catch (err) {
      errorFunction(err?.response?.data?.msg);
    }
  };

export const getCausesInfo = () => async (dispatch) => {
  try {
    const { data } = await API.getHomeCauses();
    dispatch(action.fetchCausesSuccess({ ...data }));
  } catch (err) {
    errorFunction("Something went wrong");
    dispatch(action.fetchCausesFail(err));
  }
};
